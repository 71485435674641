import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import { Box, Text, Flex, Button } from 'theme-ui'
import Section from '@components/Section'
import CardList from '@components/CardList'
import Card from '@components/Card'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/Hero'
import { useBlogTags, useBlogCategories } from '@helpers-blog'

const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const tags = useBlogTags()
  const categories = useBlogCategories()

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Flex>
            <Box>
              <Section title='Psychotherapie'>
              <Text variant='p'>
Ich sehe Psychotherapie als Hilfe zur Selbsthilfe an. Besonders wichtig ist mir 
eine vertrauensvolle und solide Therapeutische Beziehung, die nur durch
Begegnung auf Augenhöhe entstehen kann. Neben störungsspezifischen Therapiebausteinen
können schematherapeutische, imaginative, ressourcenorientierte und vor allem 
validierende Inhalte Anwendung finden.
</Text>
<Text variant='p'>
In der Psychotherapie bei Autismus-Spektrums-Störungen (ASS) geht es hauptsächlich 
um die individuell vorwiegenden Schwierigkeiten aber auch Stärken. 
Wie sonst häufig in der Verhaltenstherapie, steht hier weniger die Veränderung, 
sondern vielmehr die Akzeptanz der eigenen Wahrnehmungsverarbeitung und der damit 
einhergehenden Besonderheiten im Mittelpunkt. So können Überforderungssituationen 
besser erkannt und bestenfalls sogenannte Overloads oder Meltdowns verringert werden. 
Mitunter geraten Menschen mit ASS entweder in die Überforderung, bis hin zur Selbstaufgabe, 
durch hochgradige Anpassung, oder in die Einsamkeit durch Rückzug und Isolation. 
Beides kann schnell zur Depression führen. Ich versuche, Sie bei der Gratwanderung 
zwischen diesen beiden Polen zu unterstützen.
              </Text>
              </Section>
      <Divider />
      <Divider />
              <Section title='Diagnostik'>
              <Text variant='p'>
In den ersten Sitzungen ist eine umfangreiche Anamnese nötig, um derzeitige und frühere
Symptome richtig einzuschätzen. Ich bemühe mich darum, Ihnen gegebenenfalls Ihre Diagnose bestmöglich
zu erklären, Symptome zu erläutern und mit Ihnen zusammen möglich Ursachen in Beziehung zu setzen.
</Text>
<Text variant='p'>
Ich biete auch eine Diagnostik zur Autismus-Spektrums-Störung an. 
Im Laufe meiner psychotherapeutischen 
Tätigkeit habe ich diese schon vielfach durchgeführt. Ich verfüge also über  
Erfahrung auch im differentialdiagnostischen Bereich (Ausschluss anderer Diagnosen). 
Der Hauptbestandteil der Diagnostik ist ein ausführliches Interview, bei dem auf die ganz 
individuellen Besonderheiten eingegangen wird. Es findet ebenfalls ein fremdanamnestisches 
Interview mit einer engen Bezugsperson statt. Tests und Fragebögen ergänzen bei der 
Diagnosefindung.
In einigen Fällen ist es notwendig, dass eine gestellte Autismus-Diagnose von einer Fachärztin / einem Facharzt
für Psychiatrie bestätigt wird.
</Text>
<Text variant='p'>
  <b>Derzeit kann ich aufgrund vieler Anfragen leider keine Autismus-Diagnostik anbieten.</b>
              </Text>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  )
}

export default Posts
